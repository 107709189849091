import { t } from '@lingui/macro'
import { ReactComponent as SettingIcon } from 'assets/svg/swap/setting.svg'
import { Box } from 'rebass'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const Button = styled.button<{ isActive: boolean }>`
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  :not([disabled]):hover {
    opacity: 0.7;
  }

  ${({ isActive }) => isActive && `opacity: 0.7`}
`

const Setting = styled(SettingIcon)`
  path {
    /* fill: ${({ theme }) => theme.textSecondary}; */
  }
`
const StyledSettingTab = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 6px;
  background: linear-gradient(
    270deg,
    rgba(255, 204, 0, 0.12) 0%,
    rgba(20, 182, 0, 0.12) 50%,
    rgba(0, 126, 73, 0.12) 100%
  );
  border-radius: 12px;
`


const ButtonContent = ({allowedSlippage}:any) => {
  // const [userSlippageTolerance] = useUserSlippageTolerance()

  return (
    <StyledSettingTab>
    {allowedSlippage && <ThemedText.TextPrimary fontSize={12} fontWeight={400} color="primaryBtn">
    {allowedSlippage.toFixed(2)}%
  </ThemedText.TextPrimary>}
    <Setting />
    </StyledSettingTab>
    // <IconContainer>

    // </IconContainer>
  )

  // if (userSlippageTolerance === SlippageTolerance.Auto) {
  //   return (
  //     <IconContainer>
  //       <Icon />
  //     </IconContainer>
  //   )
  // }

  // const isInvalidSlippage = validateUserSlippageTolerance(userSlippageTolerance) !== SlippageValidationResult.Valid

  // return (
  //   <IconContainerWithSlippage data-testid="settings-icon-with-slippage" gap="sm" displayWarning={isInvalidSlippage}>
  //     <ThemedText.Caption>
  //       <Trans>{userSlippageTolerance.toFixed(2)}% slippage</Trans>
  //     </ThemedText.Caption>
  //     <Icon />
  //   </IconContainerWithSlippage>
  // )
}

export default function MenuButton({
  disabled,
  onClick,
  isActive,
  allowedSlippage
}: {
  disabled: boolean
  onClick: () => void
  isActive: boolean
  allowedSlippage?: any
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      isActive={isActive}
      id="open-settings-dialog-button"
      data-testid="open-settings-dialog-button"
      aria-label={t`Transaction Settings`}
    >

      <ButtonContent allowedSlippage={allowedSlippage} />
    </Button>
  )
}
