import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
  PosnReader?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

const BLAST_TEST_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xD9D07283566cDf97437faD2eeeE7F7Ca95dc799f',
  tradeRouter: '0xF8FDfb0074aE187e48aC55332750dCF825a2a046',
  nonfungiblePositionManagerAddress: '0x3B13e91AB27383aBf239bfA1bE4F69c663D54161',
  swapRouterAddress: '0x9f0359492A8dAfb9C5bfbE3246fD874817A9b7ba',
  quoterAddress: '0x3A292939e8151C3CB7A85c738Ee0497dce8ecfbC',
  tickLensAddress: '0x5e62b3aCAa4d892e8696e2dddf573648207Db4B5',
  roxUtilsAddress: '0x2B2A893BdF764C1B0F06D2C59ECDBf7291b10d1C',
  tradeReaderAddress: '0x6209b245D1378B59dEb361BA0132DEAc81007aB6',
  orderBookAddress: '0x53D6aBE3B585E6b491D354b8098f6b1611Aa7F52',

  multicallAddress: '0xB2f67eA43943e6C14580B9ECc88B3b05969eD4b3',

  lockAddress: '0xbD0c3e5cc7aBc6033f9E7C96d603278A0D5929A2',
  rougexTokenAddress: '0x91Da16c662ff307dE7A80C4D8B95b1fA866e50af',
  rewardsDistributorAddress: '0x76A8d84aBeCFf68a53a7014763BE9623990b64E5',

  voterAddress: '0xE3Ddfc574fA73ae369541c1303ae2Fce8AB292ee',
  minterAddress: '0x6E61230570F1600403aaD50b6a72ec0f1a53C031',
  PosnReader: '0xf4bF098950Fb8F603203B78714fA0F8B2A7041b9',
  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
}

const BLAST_MAIN_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xbd9215e002E4344c8333fc0bf4F5ECEd62BF9B85',
  tradeRouter: '0x090787f79911b19C8DbF1261Abe4FF5b4B833Bd7',
  nonfungiblePositionManagerAddress: '0x8632A0b0095f6c6D51A03E245D93B25eD2b6F709',
  swapRouterAddress: '0xc8CBb74fc79F85d1Df0aB89D5a7b6766D237EbfC',
  quoterAddress: '0x88332d6e6aD87e2C72BF04c04a5057947D438885',
  tickLensAddress: '0xf0ca239Cbb24F33Fb92A9dB1D47e62feD6cDa8a4',
  roxUtilsAddress: '0xc9bCbF1Bb96c5b19513aF64464dEa8C69c790369',
  tradeReaderAddress: '0xb81B69DC539eD055d0547aAb96dA7D1BaeC4D7Ad',
  PosnReader: '0xBb731A4d6C563e8A48871479511930F98C37e6d4',
  orderBookAddress: '0x18aa46961E952812C62B9Ffc9aA7780AAAdb3826',

  multicallAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',

  lockAddress: '0x717aA1606F1891F9Dc124381a9ba16B51FFCF396',
  rougexTokenAddress: '0x93E069A379b1Db74C10635893AbeC755CB8fD62e',
  rewardsDistributorAddress: '0xD4D589602441eCd9833CB1e05e542474D4c95512',

  voterAddress: '0x21F758179a1e40007234B6f3B0688d135864Caf8',
  minterAddress: '0xd702783F90e4573B2906b558B625b53ceDD72542',

  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
}
/*
 "WETH" : "0x4200000000000000000000000000000000000006",

    "rougeX" : "0x05956a4674945DEE1E00442a53c562350282C340",
    "AlgebraInterfaceMulticall" : "0xf26Bd9BA435395F26634C9Be5b717C6d10675897",
    "RoguexFactory" : "0xdf119e932877E61e5C2DB627C70130D37E45814C",
    "RoxUtils" : "0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0",
    "PerpUtils" : "0x9C6C26F5D308B22254a57a87744E0c018BE20dC7",
    "RoxSpotPoolDeployer" : "0xDa8B685ceB09D7cb990CE155a3eA91b7D047e033",
    "RoxPerpPoolDeployer" : "0xF22FF8f7f430a2d07efB88015853D52C88BC286d",
    "RoxPosnPoolDeployer" : "0x8F833d4314377542107E79bf7005374A42c4499D",
    "NonfungiblePositionManager" : "0x386d7bE5793B061F01601F606f28E1017Cbc9E68",
    "Quoter" : "0x37e33B250985FAf4DBdc607FA7454CA76B883A2a",
    "TickLens" : "0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b",
    "PerpRouter" : "0x859997D5686486b023b4fC375f6356FEDf40A923",
    "SwapRouter" : "0xdD84404e9741fB1Ba2f52906B25126fAE22b4243",
    "PerpOrderbook" : "0x8e7a5187117F3dfbae028ac26db00A75aE41F095",
    "VeArtProxy" : "0x648AA4e686F538e77EF0DEba5362009483EDC5cb",
    "VotingEscrow" : "0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905",
    "RewardsDistributor" : "0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21",
    "MasterChefFactory" : "0x84f7Df1129B8cD21ab4920BE6EaD959E33Ed6b86",
    "SwapMinningFactory" : "0xC37B383B80cd1E29136FCbC54dE77373ccA96479",
    "VotingRewardFactory" : "0xC1D22E673fbCA772BE2c5EbB86e7Eac72C91d885",
    "Voter" : "0x03245900162B798726514d9c3c18c1C8EfF2952f",
    "Minter" : "0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967",
    "DisFeeReward" : "0x7F4701CF364F0481d37272590CbB5F809C8c3B0F",
    "HypervisorFactory" : "0x662267CaA600d9f284356075C3504E78408A819B",
    "Reader" : "0xcd16b287536FF7A761B3574066a91e95635481ec",
    "PosnReader" : "0xee75742daFE25220337009949c0C529f56904151",
*/
const ZIRCUIT_TEST_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',
  tradeRouter: '0x859997D5686486b023b4fC375f6356FEDf40A923',
  nonfungiblePositionManagerAddress: '0x386d7bE5793B061F01601F606f28E1017Cbc9E68',
  swapRouterAddress: '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
  quoterAddress: '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
  tickLensAddress: '0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b',
  roxUtilsAddress: '0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0',
  tradeReaderAddress: '0xcd16b287536FF7A761B3574066a91e95635481ec',
  PosnReader: '0xee75742daFE25220337009949c0C529f56904151',
  orderBookAddress: '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',

  multicallAddress: '0xf26Bd9BA435395F26634C9Be5b717C6d10675897',

  lockAddress: '0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905',
  rougexTokenAddress: '0x05956a4674945DEE1E00442a53c562350282C340',
  rewardsDistributorAddress: '0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21',

  voterAddress: '0x03245900162B798726514d9c3c18c1C8EfF2952f',
  minterAddress: '0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967',
}
/*
"WETH" : "0x4200000000000000000000000000000000000006",
"rougeX" : "0xf26Bd9BA435395F26634C9Be5b717C6d10675897",
"AlgebraInterfaceMulticall" : "0xdf119e932877E61e5C2DB627C70130D37E45814C",
"RoguexFactory" : "0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0",
"RoxUtils" : "0x9C6C26F5D308B22254a57a87744E0c018BE20dC7",
"PerpUtils" : "0xDa8B685ceB09D7cb990CE155a3eA91b7D047e033",
"RoxSpotPoolDeployer" : "0xF22FF8f7f430a2d07efB88015853D52C88BC286d",
"RoxPerpPoolDeployer" : "0x8F833d4314377542107E79bf7005374A42c4499D",
"RoxPosnPoolDeployer" : "0x386d7bE5793B061F01601F606f28E1017Cbc9E68",
"NonfungiblePositionManager" : "0x37e33B250985FAf4DBdc607FA7454CA76B883A2a",
"Quoter" : "0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b",
"TickLens" : "0x859997D5686486b023b4fC375f6356FEDf40A923",
"PerpRouter" : "0xdD84404e9741fB1Ba2f52906B25126fAE22b4243",
"SwapRouter" : "0x8e7a5187117F3dfbae028ac26db00A75aE41F095",
"PerpOrderbook" : "0x648AA4e686F538e77EF0DEba5362009483EDC5cb",
"VeArtProxy" : "0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905",
"VotingEscrow" : "0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21",
"RewardsDistributor" : "0x84f7Df1129B8cD21ab4920BE6EaD959E33Ed6b86",
"MasterChefFactory" : "0xC37B383B80cd1E29136FCbC54dE77373ccA96479",
"SwapMinningFactory" : "0xC1D22E673fbCA772BE2c5EbB86e7Eac72C91d885",
"VotingRewardFactory" : "0x03245900162B798726514d9c3c18c1C8EfF2952f",
"Voter" : "0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967",
"Minter" : "0x7F4701CF364F0481d37272590CbB5F809C8c3B0F",
"DisFeeReward" : "0x662267CaA600d9f284356075C3504E78408A819B",
"HypervisorFactory" : "0xcd16b287536FF7A761B3574066a91e95635481ec",
"Reader" : "0xee75742daFE25220337009949c0C529f56904151",
"PosnReader" : "0xFE4F65b03D02944995B31Dc645A2a33021111DF8",
*/
const ZIRCUIT_MAIN_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0',
  tradeRouter: '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
  nonfungiblePositionManagerAddress: '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
  swapRouterAddress: '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',
  quoterAddress: '0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b',
  tickLensAddress: '0x859997D5686486b023b4fC375f6356FEDf40A923',
  roxUtilsAddress: '0x9C6C26F5D308B22254a57a87744E0c018BE20dC7',
  tradeReaderAddress: '0xee75742daFE25220337009949c0C529f56904151',
  PosnReader: '0xFE4F65b03D02944995B31Dc645A2a33021111DF8',
  orderBookAddress: '0x648AA4e686F538e77EF0DEba5362009483EDC5cb',

  multicallAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',

  lockAddress: '0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21',
  rougexTokenAddress: '0xf26Bd9BA435395F26634C9Be5b717C6d10675897',
  rewardsDistributorAddress: '0x84f7Df1129B8cD21ab4920BE6EaD959E33Ed6b86',

  voterAddress: '0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967',
  minterAddress: '0x7F4701CF364F0481d37272590CbB5F809C8c3B0F',
}


const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: BLAST_TEST_ADDRESSES,
  [ChainId.GOERLI]: BLAST_TEST_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: BLAST_TEST_ADDRESSES,
  [ChainId.BLAST_TEST]: BLAST_TEST_ADDRESSES,
  [ChainId.BLAST_MAIN]: BLAST_MAIN_ADDRESSES,
  [ChainId.ZIRCUIT_TEST]: ZIRCUIT_TEST_ADDRESSES,
  [ChainId.ZIRCUIT_MAIN]: ZIRCUIT_MAIN_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const POSN_READER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].PosnReader
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
